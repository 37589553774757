import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../login/loginModel';
import { TipoUsuarioModel, UsuarioModel } from '../usuarios/usuariosModel';

@Injectable()
export class UsuariosService {

  Rota: String = this.globalFunc.APIUrl() + "/apiwsi";

  constructor(
    private http: HttpClient,
    private globalFunc: GlobalFunctions
  ) { }

  GetAllTipoUsuario() {
    return this.http.get(`${this.Rota}/GetAllTipoUsuario/${localStorage.getItem("idusuarios")}`)
    .toPromise()
    .then(response => <TipoUsuarioModel[]>response)
    .then(data => {
        return <TipoUsuarioModel[]>data['response'];
    });
  }

  GetAllUsuarios() {
    return this.http.get(`${this.Rota}/GetAllUsuarios/${localStorage.getItem("idusuarios")}`)
    .toPromise()
    .then(response => <UsuarioModel[]>response)
    .then(data => {
        return <UsuarioModel[]>data['response'];
    });
  }

  InsertUsuario(Form: UsuarioModel) {
    return this.http.post(`${this.Rota}/InsertUsuario`, Form)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }

  UpdateUsuario(Form: UsuarioModel) {
    return this.http.patch(`${this.Rota}/UpdateUsuario`, Form)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }

  DeleteUsuario(idusuarios: number) {
    return this.http.delete(`${this.Rota}/DeleteUsuario/${idusuarios}`)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }

  MudaHabUsuario(idusuarios: number) {
    return this.http.get(`${this.Rota}/MudaHabUsuario/${idusuarios}`)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }


}
