import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../login/loginModel';
import { JogosModel } from './jogosModel';

@Injectable()
export class JogosService {

  Rota: String = this.globalFunc.APIUrl() + "/apiwsi";

  constructor(
    private http: HttpClient,
    private globalFunc: GlobalFunctions
  ) { }

  GetAllJogos() {
    return this.http.get(`${this.Rota}/GetAllJogos`)
    .toPromise()
    .then(response => <JogosModel[]>response)
    .then(data => {
        return <JogosModel[]>data['response'];
    });
  }

  InsertJogo(Form: JogosModel) {
    return this.http.post(`${this.Rota}/InsertJogo`, Form)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }

  UpdateJogo(Form: JogosModel) {
    return this.http.patch(`${this.Rota}/UpdateJogo`, Form)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }

  DeleteJogo(idjogos: number) {
    return this.http.delete(`${this.Rota}/DeleteJogo/${idjogos}`)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }

  MudaHabJogo(idjogos: number) {
    return this.http.get(`${this.Rota}/MudaHabJogo/${idjogos}`)
    .toPromise()
    .then(response => <ResponseModel>response)
    .then(data => {
        return <ResponseModel>data;
    });
  }


}
