import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../login/loginModel';
import { JogosModel } from './jogosModel';
import { JogosService } from './jogosService';

@Component({
  selector: 'app-jogos',
  templateUrl: './jogos.component.html',
  styleUrls: ['./jogos.component.css']
})
export class JogosComponent implements OnInit {

  FormJogo: JogosModel = new JogosModel();
  ListaJogos: JogosModel[];
  uploading: boolean = false;
  progressUpload: number = 0;
  linkJogo: SafeResourceUrl;
  disBtn: boolean = false;
  ColunasJogos: any[];
  Carregado: boolean = false;
  ModoCadastro: boolean = false;
  Editando: boolean = false;
  btnNovoText: string = "Novo";

  constructor(
    private jogosService: JogosService,
    private globalFunc: GlobalFunctions,
  ) { }

  ngOnInit() {
    this.ColunasJogos = [
      { field: 'idjogos', header: 'ID' },
      { field: 'descricao', header: 'Descrição' },
      { field: 'habilitado', header: 'Habilitado' },
    ];
    this.AtualizarDados();
  }

  AtualizarDados() {
    this.Carregado = false;
    this.FormJogo = new JogosModel();
    this.jogosService.GetAllJogos().then(resJogos => {
      this.ListaJogos = resJogos;
      this.Carregado = true;
      this.ModoCadastro = false;
      this.Editando = false;
    });
  }

  Novo() {
    this.FormJogo = new JogosModel();
    if (this.btnNovoText == "Novo") {
      this.ModoCadastro = true;
      this.Editando = false;
      this.btnNovoText = "Cancelar";
    }
    else {
      this.ModoCadastro = false;
      this.Editando = false;
      this.btnNovoText = "Novo";
    }
  }

  Salvar() {
    console.log(this.FormJogo);
    let Erro: string = this.globalFunc.VerificaCaractereNoCampo(this.FormJogo, ["", null], ["idjogos", "Arquivo", "insert_date", "update_date"]);
    if (Erro) {
      alert("Preencha corretamente os campos!\r\nCampo obrigatório: " + Erro);
      return;
    }

    if (!this.Editando) {
      this.jogosService.InsertJogo(this.FormJogo).then(resInsert => {
        this.FormJogo.idjogos = resInsert.response.insertId;

        this.FinalizaAlteracoes(resInsert);
      });
    }
    else {
      this.jogosService.UpdateJogo(this.FormJogo).then(resUpdate => {
        this.FinalizaAlteracoes(resUpdate);
      });
    }
  }

  FinalizaAlteracoes(response: ResponseModel) {
    if (response.status == 200) {
      alert(`Jogo ${(!this.Editando) ? 'cadastrado' : 'atualizado'} com sucesso!`);
      if (this.FormJogo.Arquivo) {
        let Variavel: string = "UploadGame";
        this.globalFunc.uploadFiles([{header: "name", data: this.FormJogo.idjogos }, {header: "file", data: this.FormJogo.Arquivo}], "POST", "/apiwsi/CopiarJogo", Variavel);
        this.verifyEndUpload(Variavel);
      }
      else {
        this.AtualizarDados();
      }
    }
    else {
      alert(`Erro ao ${(!this.Editando) ? 'cadastrar' : 'atualizar'} jogo!`);
      console.log(response.error);
    }
    this.Novo();
  }

  Editar(Jogo: JogosModel) {
    this.Novo();
    this.FormJogo = new JogosModel();
    this.FormJogo.habilitado = Jogo.habilitado;
    this.FormJogo.descricao = Jogo.descricao;
    this.FormJogo.idjogos = Jogo.idjogos;
    this.Editando = true;
  }

  Excluir(Jogo: JogosModel) {
    if (!confirm("Tem certeza que deseja excluir esse jogo?"))
      return;

    this.jogosService.DeleteJogo(Jogo.idjogos).then(resDelete => {
      if (resDelete.status == 200) {
        alert("Jogo excluído com sucesso!");
        this.AtualizarDados();
      }
      else {
        alert("Erro ao excluir jogo!");
      }
    });
  }

  MudarStatus(Jogo: JogosModel) {
    this.jogosService.MudaHabJogo(Jogo.idjogos).then(resUpdate => {
      if (resUpdate.status == 200) {
        this.AtualizarDados();
      }
      else {
        alert("Erro ao mudar status do jogo!");
        console.log(resUpdate.error);
      }
    });
  }


  async loading(event) {
    if(event.lengthComputable) {
      var percentLoaded = Math.round((event.loaded / event.total) * 100);
      if (percentLoaded < 100) {
        this.progressUpload = percentLoaded;
      }
    }
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      this.FormJogo.Arquivo = event.target.files[0];
      this.FormJogo.descricao = this.FormJogo.Arquivo.name.substring(0, this.FormJogo.Arquivo.name.length - 4);
      this.uploading = true;
      //this.showToast("success", "Abrindo", "Aguarde enquanto o arquivo é carregado");
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onprogress = (event) => {
        this.loading(event);
      }
      reader.onload = (event) => {
        //this.showToast("success", "Abrindo", "Arquivo carregado com sucesso!");
        this.uploading = false;
      }
    }
  }

  verifyEndUpload(Variavel: string) {
    setTimeout(() => {
      if (localStorage.getItem(Variavel) != "0") {
        alert("Jogo copiado com sucesso!");
        this.AtualizarDados();
      }
      else {
        this.verifyEndUpload(Variavel);
      }
    }, 200);
  }

}
